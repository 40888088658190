import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {SCHOOL_PAGE_LOADED,ADD_SCHOOL,DELETE_SCHOOL, SUBJECT_LOAD_AND_COURSE} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.school 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => 
     payload.then(res=> dispatch({ type: SCHOOL_PAGE_LOADED, data:res[0] }) ),  
     
});

class SchoolsList extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",            
            isLoading: true,
            currentPageNumber:0,
            loginClass: '',
            name:'',
            state:'',
            city:'',
            pin_code:'',
            status:''
        };
        
    }
    submitForm=() => (ev) => {
        ev.preventDefault(); 
        this.loadData(0);
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }
    loadData = (page,actionCheck) => {
        this.setState({ isLoading: true ,currentPageNumber:page});
        var result ='actionCheck='+actionCheck;
        let queryObject = {name:this.state.name,state:this.state.state,city:this.state.city,pin_code:this.state.pin_code,status:this.state.status};
        
        var result = [];
        var keys = Object.keys(queryObject);
        keys.forEach(function(key){
            result.push(key+"="+queryObject[key]);
        });
        result = result.join('&');
        this.props.onLoad(Promise.all([school_api.Schools.all(page,result)]));
    }
    componentDidMount() {
         this.loadData(0);
    }

     updateStatus = (status,sid) =>{
        var object = JSON.stringify({status:status,sid:sid});
         
        this.loadData(this.state.currentPageNumber,object);
    }

    deleteCenter = (id) => {
        this.setState({ isLoading: true }); 
        if(window.confirm("Are sure want to delete school and their students?")) {
            school_api.Schools.del(id).then(res => {
                this.setState({ isLoading: false }); 
                this.props.onLoad(Promise.all([school_api.Schools.all(0)])); 
            });
        }
    }
  shouldComponentUpdate(nextProps){
    if(nextProps.schools && this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
    return true;
  }

    render() {
        var incStart = (this.props.current_page-1)*10;
         var inc=1+incStart;
        return ( 
           <div>
               
            <form onSubmit={this.submitForm()}  >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                    <div className="header">
                        <h2>Search</h2>
                    </div>
                    <div className="body">
                        
                        <div className="row clearfix">
                            <div className="col-md-3">
                                <label htmlFor="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="name_sub" className="form-control" onChange={this.changeValue('name')} value={this.state.name} placeholder="Enter Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="email_address">Course</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="state" className="form-control" onChange={this.changeValue('state')} value={this.state.state} placeholder="Enter State" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="email_address">City</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="type_city" onChange={this.changeValue('city')} value={this.state.city} className="form-control" placeholder="Enter City" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="email_address">Pincode</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="pincode" onChange={this.changeValue('pin_code')} value={this.state.pin_code} className="form-control" placeholder="Enter pincode" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="email_address">Status</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="status" onChange={this.changeValue('status')} value={this.state.status} className="form-control" placeholder="Enter status" />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button class="btn btn-primary waves-effect" type="submit">Search</button>
                    </div>
                </div>
        </div>
        </form>
        
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    List 
                                    {this.props.addSchool && 
                                     <Link to="/schools/add" className="btn btn-danger waves-effect">Add</Link>
                                    }
                                     <span style={{float:'right'}}>Total : {this.props.total}</span>
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">

                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Name</th>
                                                <th>Total Student</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Pincode</th>
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                        {this.props.schools && this.props.schools.map(item=>(
                                            <tr key={item.id}>
                                                <td>{inc++}</td>
                                                <td>{item.name}</td>
                                                
                                                <td>{item.totalStudent}</td>
                                                <td>{item.state}</td>
                                                <td>{item.city}</td>
                                                <td>{item.pin_code}</td>
                                                <td><a href="javascript:" onClick={()=>this.updateStatus(item.status,item.id)} > {item.status}</a>
                                                </td>
                                                <td>{item.created_at}</td>

                                                <td>
                                                {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                                                <div> <Link to={`/schools/edit/${item.id}`} title="Edit"   ><i class="material-icons">edit</i></Link>

                                                 <a  href="javascript:" onClick={() => this.deleteCenter(item.id)} title="Delete" ><i class="material-icons">delete</i></a>

                                                 <Link to={`/schools/student/${item.id}`}  title="Add Student" ><i class="material-icons">add</i></Link> 
                                                 </div>
                                                }
                                                
                                                {(this.props.dashboard.user && this.props.dashboard.user.id!=1 && item.add_student=='Active') &&
                                                <div>
                                                 <Link to={`/schools/student/${item.id}`}  title="Add Student" ><i class="material-icons">add</i></Link> 
                                                 </div>
                                                }
                                                
                                                </td>
                                            </tr>
                                        ))}
                                             
                                        </tbody>
                                    </table>
                                </div>
                            <Pagination current_page={this.props.current_page} total={this.props.total} last_page={this.props.last_page} loadData={this.loadData} />
                            </div>
                        </div>
                        <Loader isloading={this.state.isLoading}/>
                    </div> 
                    </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsList);
