import React from "react";
import CourseList from "./CourseList";
import CourseAdd from  "./CourseAdd";
import { Switch, Route } from "react-router-dom"; 
 

class Courses extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!", 
        };
    }

    componentDidMount() {
         
    }

    render() {
        
        return (
        <section className="content">
            <div className="container-fluid">
            <div className="block-header">
                <h2>Courses</h2>
            </div> 
            <div className="row clearfix">
            {this.props.match.params.action == 'list' && <CourseList/>  }
            {this.props.match.params.action == 'add' && <CourseAdd  dashboard={this.props.dashboard} /> }
            {this.props.match.params.action == 'edit' && <CourseAdd  sid={this.props.match.params.id}  dashboard={this.props.dashboard} /> }
            </div>
            </div>
        </section>
        );
    }
}

export default Courses;
