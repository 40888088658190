import {
    DASHBOARD_LOAD
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case DASHBOARD_LOAD:
        return {
          ...state,
          loading:false,
          school_list: action.school_list ? action.school_list : {},
          students: action.students ? action.students : {},
          user: action.user ? action.user : {},
          course_subject: action.course_subject ? action.course_subject : {},
        };     
      default:
        return state;
    }
  };
  