import React from "react"; 
 

class Loader extends React.Component {
    constructor() {
        super();
        this.state = {
            current_page:0,
            last_page:0,
            total:0
        };
        
    }

    sendToPagination = (page) => {
        if(this.props.current_page!=page) {
            this.props.loadData(page)
        }
    }

    sendNextPage = () => {
      let np = this.props.current_page +1;
       
      if(this.props.last_page >= np)   {
          this.sendToPagination(np);
      }
    }

    sendPrevPage = () => {
        let np = this.props.current_page -1;
      if(np>0 && this.props.last_page>= np)   {
          this.sendToPagination(np);
      }
    }

    componentDidMount() { 

    }

    render() {
        let lastPage = this.props.last_page;
        let startNumber = 1;
        let endNumber = this.props.last_page>10 ? 10 : this.props.last_page;
        if(this.props.last_page>10){
             
            if(this.props.current_page>4){
                startNumber = this.props.current_page-2;
            }
            let diff = (this.props.last_page-this.props.current_page);
            if(this.props.current_page>4 && this.props.last_page>this.props.current_page && diff>8){
                
                endNumber =  (diff>8)?this.props.current_page+8:this.props.current_page+diff;
            }
            if(diff<=8){
                startNumber = this.props.current_page-8;
                endNumber = this.props.last_page;
            }

        }

var rows = [];
for (let i = startNumber; i <= endNumber; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    rows.push(<li><a   class="waves-effect" onClick={()=>this.sendToPagination((i))} >{i}</a></li>);
}
       if(this.props.last_page > 1) {
        return ( 
            <ul class="pagination">
            <li>
                <a   onClick={()=>this.sendPrevPage()}  class="waves-effect">
                    <i class="material-icons">chevron_left</i>
                </a>
            </li>

           {rows}
             
            <li>
                <a   onClick={()=>this.sendNextPage()} class="waves-effect">
                    <i class="material-icons">chevron_right</i>
                </a>
            </li>
            </ul>
        );
       }
       return '';
     
    }
}

export default Loader;
