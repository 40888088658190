import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { fetchCircuits, fetchLogin } from "./api";
import school from './reducers/school';
import subject from './reducers/subject';
import course from './reducers/course';
import student from './reducers/student';
import user from './reducers/user';
import dashboard from './reducers/dashboard'

 //const [cookies, setCookie] = useCookies(['name']);

/*Home component reducer */
const storeData = ( data ) => ( {
    type: "STORE_DATA",
    data,
} );

export const fetchData = ( ) => ( dispatch ) =>
    fetchCircuits( ).then( res => dispatch( storeData( res ) ) );

const dataReducer = ( state = [ ], action ) => {
    switch ( action.type ) {
        case "STORE_DATA":
            return action.data;
        default: return state;
    }
};

/*Checked logged in*/
const checkedLoggedIn = ( state = {}, action ) => {
    switch ( action.type ) {
        case "IS_LOGGED_IN":  
            
            return  action.data ? true : false ;
        default: 
            return state;
    }
};
export const fetchLoggedIn = ( ) => ( dispatch ) =>{
    console.log(dispatch);
     dispatch(  {type:'IS_LOGGED_IN',data:false} );
 }

/*Login User Reducer*/
const storeLoginData = ( data ) => ( {
    type: "USER_LOGIN",
    data,
});

export const fetchLoginData = (email, password ) => ( dispatch ) =>
    fetchLogin(email, password ).then( res => 
         {
            
            if(res.success == true){
                localStorage.setItem('access_token',res.data.access_token);
                localStorage.setItem('user_detail',JSON.stringify(res.data));
            }
            dispatch( storeLoginData( res ) )             
            dispatch(  {type:'IS_LOGGED_IN',data:res.success} );
         }
        );

const userLoginReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case "USER_LOGIN":
            return  action.data;
        default: 
            return state;
    }
};
 



const reducer = combineReducers( {  
    school:school ,
    course:course ,
    student:student ,
    subject:subject ,
    user:user,
    dashboard:dashboard,
    userLogin: userLoginReducer, 
    isLoggedIn: checkedLoggedIn   
} );

export default ( initialState ) =>
    createStore( reducer, initialState, applyMiddleware( thunkMiddleware ) );
