export const ADD_SCHOOL = 'ADD_SCHOOL';
export const GET_SCHOOL = 'GET_SCHOOL';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const SCHOOL_PAGE_LOADED = 'SCHOOL_PAGE_LOADED';
export const SUBJECT_LOAD_AND_COURSE = 'SUBJECT_LOAD_AND_COURSE';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';

export const ADD_SUBJECT = 'ADD_SUBJECT';
export const GET_SUBJECT = 'GET_SUBJECT';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const SUBJECT_PAGE_LOADED = 'SUBJECT_PAGE_LOADED';
export const DELETE_SUBJECT = 'DELETE_SUBJECT';


export const ADD_USER = 'ADD_USER';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_PAGE_LOADED = 'USER_PAGE_LOADED';
export const DELETE_USER = 'DELETE_USER';

export const ADD_STUDENT = 'ADD_STUDENT';
export const GET_STUDENT = 'GET_STUDENT';
export const GET_STUDENT_INFO = 'GET_STUDENT_INFO';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_MARKS = 'UPDATE_MARKS';
export const STUDENT_PAGE_LOADED = 'STUDENT_PAGE_LOADED';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const UPLOAD_DOCUMENT = 'DELETE_STUDENT';

export const ADD_COURSE = 'ADD_COURSE';
export const GET_COURSE = 'GET_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const COURSE_PAGE_LOADED = 'COURSE_PAGE_LOADED';
export const DELETE_COURSE = 'DELETE_COURSE';

export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const DASHBOARD_LOAD = 'DASHBOARD_LOAD';