import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {USER_PAGE_LOADED,ADD_USER,DELETE_USER} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.user
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => 
     payload.then(res=> dispatch({ type: USER_PAGE_LOADED, data:res[0] }) ), 
     
});


class UsersList extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: '',
            name:'',
            email:'',
            school:'',
            status:'',
            isLoading:true
        }; 
    }
    loadData = (page) => {
        this.setState({ isLoading: true });
        let queryObject = {name:this.state.name,email:this.state.email,school:this.state.school,status:this.state.status};
        
        var result = [];
        var keys = Object.keys(queryObject);
        keys.forEach(function(key){
            result.push(key+"="+queryObject[key]);
        });
        result = result.join('&');
        this.props.onLoad(Promise.all([school_api.Users.all(page,result)]));
    }
    componentDidMount() {
         this.loadData(1);
    }
    submitForm=() => (ev) => {
        ev.preventDefault(); 
        this.loadData(0);
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }
  shouldComponentUpdate(nextProps){

    if(nextProps.datas && this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
    return true;
  }

    render() {
        var incStart = (this.props.current_page-1)*10;
        var inc=1+incStart;
        return ( 
           <div>
               <form onSubmit={this.submitForm()}  >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                    <div className="header">
                        <h2>Search</h2>
                    </div>
                    <div className="body">
                        
                        <div className="row clearfix">
                            <div className="col-md-3">
                                <label htmlFor="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="name_sub" className="form-control" onChange={this.changeValue('name')} value={this.state.name} placeholder="Enter Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="email_address">Email</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="email" className="form-control" onChange={this.changeValue('email')} value={this.state.email} placeholder="Enter Email" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="email_address">School</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="school" onChange={this.changeValue('school')} value={this.state.school} className="form-control" placeholder="Enter School" />
                                    </div>
                                </div>
                            </div>
 

                            <div className="col-md-3">
                                <label htmlFor="email_address">Status</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="status" onChange={this.changeValue('status')} value={this.state.status} className="form-control" placeholder="Enter status" />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button class="btn btn-primary waves-effect" type="submit">Search</button>
                    </div>
                </div>
        </div>
        </form>
          
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    List | <Link to="/users/add" className="btn btn-danger waves-effect">Add</Link>  <span style={{float:'right'}}>Total : {this.props.total}</span>
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr><th>Sr.No.</th>
                                                <th>Name</th>                                                
                                                <th>Email</th>
                                                <th>School</th> 
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                            {this.props.datas && this.props.datas.map(item=>(
                                            <tr key={item.id}>
                                                <td>{inc++}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.school && item.school.name}</td>
                                                <td>{item.status}</td>
                                                <td>{item.created_at}</td>
                                                <td>
                                                    <Link to={`/users/edit/${item.id}`}   ><i class="material-icons">edit</i></Link>
                                                
                                                </td>
                                            </tr>
                                        ))}

                                    { ( this.props.datas && this.props.datas.length == 0) && 
                                        
                                        <tr>
                                            <td colSpan="7">Record Not Found.</td>
                                        </tr>
                                         
                                        }
                                            
                                        </tbody>
                                       
                                    </table>
                                </div>
                                <Pagination current_page={this.props.current_page} total={this.props.total} last_page={this.props.last_page} loadData={this.loadData} />
                            </div>
                        </div>
                         <Loader isloading={this.state.isLoading}/>
                    </div> 
                    </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (UsersList);
