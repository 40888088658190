import React from "react";
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {ADD_COURSE, GET_COURSE, UPDATE_COURSE} from "../../constants/actionTypes"
import school_api from "../../school_api" 
import Loader from "../Loader/Loader";

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_COURSE, ...payload }),
  onEdit: payload =>
    dispatch({ type: GET_COURSE, ...payload }),
  onUpdate: payload =>
    dispatch({ type: UPDATE_COURSE, ...payload })
});

const mapStateToProps = state => ({
  ...state.course
}); 

class CourseAdd extends React.Component {
    constructor() {
        super();
        this.state = {
            name:'',
            id:'',
            duration:'',
            type_course:'',
            number_of_subject:'',
            optional_subject:'', 
            status:'Active',
            redirect:false,           
            isLoading: false,
            mode:'edit'
        };
        
        this.setName = ev => {
            this.setState({name: ev.target.value });
        };

        this.setDuration = ev => {
          this.setState({duration: ev.target.value });
      };

      this.setStatus = ev => {
        this.setState({status: ev.target.value });
    };

      this.setType_course = ev => {
        this.setState({type_course: ev.target.value });
      };
        this.setNumber_of_subject = ev => {
            this.setState({number_of_subject: ev.target.value });
        };
        this.setOptional_subject = ev => {
            this.setState({optional_subject: ev.target.value });
        };


        this.submitForm = () => ev => {
          ev.preventDefault(); 
          this.setState({isLoading:true});
         if(this.props.sid) {
            const payload = school_api.Courses.update(this.props.sid, this.state);
              payload.then(
                res=>{ 
                    this.props.onUpdate(res);
                    this.setState({redirect:true});
                  }, 

                error=>{this.props.onUpdate( error.response.body)} 
              ); 
         }else{
             const payload = school_api.Courses.create(this.state);
              payload.then(
                res=>{ 
                    this.props.onSubmit(res);
                    this.setState({redirect:true});
                  }, 

                error=>{this.props.onSubmit( error.response.body)} 
              ); 
         }
        };

    }

    componentDidMount() {
        if(this.props.sid) {
             this.setState({ isLoading: true });
   
            school_api.Courses.get(this.props.sid).then(res=>this.props.onEdit(res)) ;
        }
    }

   shouldComponentUpdate(nextProps){
    if(nextProps.action && this.state.isLoading == true) {
      if(this.props.success && this.state.mode=='edit' && (this.props.action != GET_COURSE)) {
           this.setState({ isLoading: false ,mode:'done'});
      }else{
        this.setState({ isLoading: false });
      }
    }

  if(this.props.sid!="" && nextProps.action == GET_COURSE && this.state.id != nextProps.data.id ) {
      this.setState({...nextProps.data,isLoading:false});
    }
    return true;
  }

    render() {
         if(this.state.redirect) {
          return <Redirect to='/courses/list' />
        }
        const active = this.state.status == 'Active' ?true:false;
        const inactive = this.state.status == 'Inactive' ?true:false;
         
        return (
            
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Form | <Link to="/courses/list" className="btn btn-danger waves-effect">Back</Link>
                            </h2>
                            
                        </div>
                        <div className="body">
                            <div>
                            { this.props.dataErrors && 
                                <ul>
                                {this.props.dataErrors && this.props.dataErrors.map( error=>(
                                    <li key={error}>{error} </li>
                                ))}
                                </ul>  
                             }
                            </div>
                            <form onSubmit={this.submitForm()}>
                                <label htmlFor="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="name" value={this.state.name} onChange={this.setName} className="form-control" placeholder="Enter your name" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Duration</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="duration" value={this.state.duration} onChange={this.setDuration} className="form-control" placeholder="Duration" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Select Course Type</label>
                                <div className="form-group">
                                    <div className="form-line">
                                      <select className="form-control" value={this.state.type_course} name="type_course" onChange={this.setType_course} >
                                            <option value="">Select Course Type</option>
                                            { (this.props.dashboard && this.props.dashboard.course_subject) && this.props.dashboard.course_subject.type_courses.map(obj=>(
                                              <option key={obj.id} value={obj.id}>{obj.name}</option>
                                            ))}
                                      </select>
                                    </div>
                                </div>

                                <label htmlFor="email_address">Number Of Subject</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="number_of_subject" value={this.state.number_of_subject} onChange={this.setNumber_of_subject} className="form-control" placeholder="Number Of Subject" />
                                    </div>
                                </div>

                                <label htmlFor="email_address">Number Of Optional Subject</label>
                                <div className="form-group">
                                    <div className="form-line">
                                        <input type="text" id="optional_subject" value={this.state.optional_subject} onChange={this.setOptional_subject} className="form-control" placeholder="Number Of Optional Subject" />
                                    </div>
                                </div>
                                <label htmlFor="password">Status</label>
                                <div className="form-group">
                                    <div  >
                                        <input type="radio" name="radio" name="status" checked={active}  value="Active"
                                        onChange={this.setStatus} />Active
                                         &nbsp;
                                         <input type="radio" name="radio" name="status" checked={inactive} value="Inactive"
                                        onChange={this.setStatus} />Inactive
                                    </div>
                                </div> 
                                <button type="submit" className="btn btn-primary m-t-15 waves-effect">Save</button>
                            </form>
                        </div>
                    </div>
                     <Loader isloading={this.state.isLoading}/>
                </div>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseAdd);
