import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {SUBJECT_PAGE_LOADED,ADD_COURSE,DELETE_SUBJECT} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";

const mapStateToProps = state => ({
  ...state.subject
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => 
     payload.then(res=> dispatch({ type: SUBJECT_PAGE_LOADED, data:res[0] }) ), 
     
});


class SubjectList extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            loginClass: '',
            duration:"",
            type_course:'',
            name_course:'',
            name_sub:'',
            isLoading:true
        };
        
    }
    loadData = (page) => {
        this.setState({ isLoading: true });
        let queryObject = {name:this.state.name_sub,course:this.state.name_course,type:this.state.type_course,duration:this.state.duration};
        
        var result = [];
        var keys = Object.keys(queryObject);
        keys.forEach(function(key){
            result.push(key+"="+queryObject[key]);
        });
        result = result.join('&');
        this.props.onLoad(Promise.all([school_api.Subjects.all(page,result)]));
    }
    componentDidMount() { 
        this.loadData(1);
    }

    submitForm=() => (ev) => {
        ev.preventDefault(); 
        this.loadData(0);
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }
  shouldComponentUpdate(nextProps){

    if(nextProps.datas && this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
    return true;
  }

    render() {
        var incStart = (this.props.current_page-1)*10;
        var inc=1+incStart;
        return ( 
            <div>

            <form onSubmit={this.submitForm()}  >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="card">
                    <div className="header">
                        <h2>Search</h2>
                    </div>
                    <div className="body">
                        
                        <div className="row clearfix">
                            <div className="col-md-3">
                                <label htmlFor="email_address">Name</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="name_sub" className="form-control" onChange={this.changeValue('name_sub')} value={this.state.name_sub} placeholder="Enter Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="email_address">Course</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="name_course" className="form-control" onChange={this.changeValue('name_course')} value={this.state.course} placeholder="Enter Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="email_address">Type</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="type_course" onChange={this.changeValue('type_course')} value={this.state.type_course} className="form-control" placeholder="Enter Type" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="email_address">Duration</label>
                                <div className="form-group">
                                    <div className="form-line ">
                                        <input type="text" id="duration" onChange={this.changeValue('duration')} value={this.state.duration} className="form-control" placeholder="Enter duration" />
                                    </div>
                                </div>
                            </div>

                             

                        </div>
                        <button class="btn btn-primary waves-effect" type="submit">Search</button>
                    </div>
                </div>
        </div>
        </form>
        
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="header">
                                <h2>
                                    List | <Link to="/subjects/add" className="btn btn-danger waves-effect">Add</Link>  <span style={{float:'right'}}>Total : {this.props.total}</span>
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Name</th> 
                                                <th>Course</th>
                                                <th>Type</th>
                                                <th>Duration</th>
                                                <th>Passing Marks</th>
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                        {this.props.datas && this.props.datas.map(item=>(
                                            <tr key={item.id}>
                                                <td>{inc++}</td>
                                                <td>{item.name}</td>                                                
                                                <td>{item.course && item.course.name}</td>
                                                <td>{item.course && item.course.type_course}</td>
                                                <td>{item.course && item.course.duration}</td>
                                                <td>{item.passing_mark}</td>
                                                <td>{item.status}</td> 
                                                <td>{item.created_at}</td>
                                                <td>
                                                <Link to={`/subjects/edit/${item.id}`}   ><i class="material-icons">edit</i></Link>
                                               {/* &nbsp;|&nbsp;
                                                <Link to={`/schools/delete/${item.id}`}   >Delete</Link>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination current_page={this.props.current_page} total={this.props.total} last_page={this.props.last_page} loadData={this.loadData} />
                            </div>
                        </div>
                        <Loader isloading={this.state.isLoading}/>
                    </div>
                
         </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SubjectList);
