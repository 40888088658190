import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import './App.css';
import createStore from "./store";
import Layout from "./components/Layout";

const store = createStore( window.REDUX_DATA );

function App() {
  return (
      <ReduxProvider store={store}>
        <Router>
            <Layout />
        </Router>
    </ReduxProvider>
  );
}

export default App;
