import React from "react"; 
import {Link} from 'react-router-dom';
import { Switch, Route } from "react-router-dom"; 
import { connect } from 'react-redux';
import school_api from "../../school_api" 
import {STUDENT_PAGE_LOADED,ADD_STUDENT,DELETE_SCHOOL} from "../../constants/actionTypes";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
const mapStateToProps = state => ({
  ...state.student 
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => {
     console.log(payload);
     payload.then(res=> dispatch({ type: STUDENT_PAGE_LOADED, data:res[0] }) )
  },  
     
});

class StudentsList extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            s_session:'',
            s_roll_no:'',
            s_center:'',
            s_course:'',          
            isLoading: true,
            currentPageNumber:0,
            actionCheck:'',
            page:0,
            loginClass: ''
        };
        
    }

    changeValue=(variable) => (ev) => {
        const obj = this.state;
        var value = ev.target.value;
        obj[variable] = value; 
        this.setState(obj);  
    }

    
    submitForm=() => (ev) => {
        ev.preventDefault(); 
        this.loadData(0);
    }

    loadData = (page,object) => {
        var actionCheck = object;
        console.log(actionCheck);
        this.setState({ isLoading: true ,currentPageNumber:page, actionCheck:''});
        let queryObject = {session:this.state.s_session,course:this.state.s_course,roll_no:this.state.s_roll_no,center:this.state.s_center, actionCheck:actionCheck};
        
        var result = [];
        var keys = Object.keys(queryObject);
        keys.forEach(function(key){
            result.push(key+"="+queryObject[key]);
        });
        result = result.join('&');
        this.props.onLoad(Promise.all([school_api.Students.all(this.props.school_id,page,result)]));
    }

    updateStatus = (status,sid) =>{
        var object = JSON.stringify({status:status,sid:sid});
         
         
        this.loadData(this.state.currentPageNumber,object);
    }

    componentDidMount() {
        this.loadData(0);
    }

    generateAdmitCard = (studentId) => {
        this.setState({ isLoading: true }); 
        school_api.Students.admit_card(studentId).then(res => {
            this.setState({ isLoading: false }); 
            window.open(`${school_api.HOST_ROOT}/student/generate-admin-card/${studentId}`, '_blank'); 
        });
    }
    deleteStudent = (id) => {
        this.setState({ isLoading: true }); 
        if(window.confirm("Are sure want to delete students?")) {
            school_api.Students.del(id).then(res => {
                this.setState({ isLoading: false }); 
                this.props.onLoad(Promise.all([school_api.Students.all(this.props.school_id,0)]));
            });
        }
    }
    shouldComponentUpdate(nextProps){

        if(nextProps.datas && this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
        return true;
    }

    render() {
        var incStart = (this.props.current_page-1)*10;
        var inc=1+incStart;
        return ( 
           <div>
               <form onSubmit={this.submitForm()}  >
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="header">
                            <h2>
                                Search
                            </h2>
                        </div>
                        <div className="body">
                            
                            <div className="row clearfix">
                                <div className="col-md-3">
                                    <label htmlFor="email_address">Study center code</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_center_code" className="form-control" onChange={this.changeValue('s_center')} value={this.state.s_center} placeholder="Enter Study center code" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="email_address">Session</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_session" onChange={this.changeValue('s_session')} value={this.state.s_session} className="form-control" placeholder="Enter session" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="email_address">Roll No.</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_roll_no" onChange={this.changeValue('s_roll_no')} value={this.state.s_roll_no} className="form-control" placeholder="Enter roll no" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="email_address">Course Name</label>
                                    <div className="form-group">
                                        <div className="form-line ">
                                            <input type="text" id="s_course" value={this.state.s_course} onChange={this.changeValue('s_course')} className="form-control" placeholder="Enter course" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <button class="btn btn-primary waves-effect" type="submit">Search</button>
                        </div>
                    </div>
            </div>
            </form>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <div className="card">
                            <div className="header">
                                <h2>
                                    Students List  
                                    <span style={{float:'right'}}>Total : {this.props.total}</span>
                                </h2>
                                
                            </div>
                            <div className="body">
                                <div className="table-responsive">

                                    <table className="table table-bordered table-striped table-hover js-basic-example dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Study center code</th>
                                                <th>Course</th>
                                                <th>Duration</th>
                                                <th>Session</th>
                                                <th>Roll no</th>  
                                                <th>Name</th> 
                                                <th>Status</th>
                                                <th>Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                     
                                        <tbody>
                                        {this.props.datas && this.props.datas.map((item,i)=>(
                                            <tr key={item.id}>
                                                <td>{inc++}</td>
                                                <td>{item.pin_code}</td>
                                                <td>{item.course.name}</td>
                                                <td>{item.course.duration}</td>
                                                <td>{item.session}</td>
                                                <td>{item.roll_no}</td>
                                                <td>{item.student.name}</td> 
                                                <td> <a href="javascript:" onClick={()=>this.updateStatus(item.student.status,item.student.id)} > {item.student.status}</a></td>
                                                <td>{item.created_at}</td>
                                                <td>
                                               {((this.props.dashboard.user && this.props.dashboard.user.id==1)  || (this.props.dashboard.user && this.props.dashboard.user.admit_card=="Yes")) && <a href="javascript:" title="Download Admit Card"  onClick={ ()=> this.generateAdmitCard(`${item.student.id}-${item.course.id}`)}   ><i class="material-icons">card_membership</i></a>

                                                }

                                                 <Link to={`/schools/upload-document/${this.props.school_id}/${item.student.id}-${item.id}-${item.course.id}`} title="Upload Document"  ><i class="material-icons">file_upload</i></Link>
                                                 {(this.props.dashboard.user && this.props.dashboard.user.id==1) &&
                                                    <div>
                                                   <Link to={`/students/edit/${item.student.id}`} title="Edit Student"  ><i class="material-icons">edit</i></Link> 
                                                 <Link to={`/schools/upload-marks/${this.props.school_id}/${item.student.id}-${item.id}-${item.course.id}`} title="Upload Marks"  ><i class="material-icons">assignment</i></Link>  
                                                 
                                                 
                                                 <a  href="javascript:" onClick={() => this.deleteStudent(item.student.id)} title="Delete Statudent" ><i class="material-icons">delete</i></a>
                                                 </div>
                                                 }                                          
                                                </td>
                                            </tr>
                                        ))}
                                             
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination current_page={this.props.current_page} total={this.props.total} last_page={this.props.last_page} loadData={this.loadData} />
                            </div>
                        </div>
                        <Loader isloading={this.state.isLoading}/>
                    </div>
                </div>
         
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);
