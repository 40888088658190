import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header"; 
import Menu from "./Menu"; 
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import Schools from "../Schools/Schools";
import Courses from "../Courses/Courses";
import Subjects from "../Subjects/Subjects"; 
import Students from "../Students/Students";
import Users from "../Users/Users"; 
import createStore, { initializeSession } from "../../store"; 
import {DASHBOARD_LOAD} from "../../constants/actionTypes";
import school_api from "../../school_api";
import Loader from "../Loader/Loader";

const store = createStore( );


const mapStateToProps = (state) => ({
    ...state.dashboard 
 });

 const mapDispatchToProps = dispatch => ({
    onLoad: payload => 
            dispatch({ type: DASHBOARD_LOAD, ...payload }), 
       
  });

class Layout extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "Welcome to React SSR!",
            isLoading:false,
            loaderShow:false,
            isLoggedIn:false 
        };
          
         
        this.changeHandleLoading = this.changeHandleLoading.bind(this);
          
    }
    

    componentDidMount() {
        const token = window.localStorage.getItem('access_token'); 
        if(token!=null) {
            this.setState({loaderShow:true});
            const payload = school_api.Dashboard.current();
            payload.then(
            res=>{     
                this.setState({isLoggedIn:true,loaderShow:false});
                this.props.onLoad(res);
                 
                }, 

            error=>{
                   window.localStorage.removeItem('access_token');
                   window.location.href="/";
                } 
            ); 
        }
        
    } 
    setLogin = (getValue) => {
        
        if(this.state.isLoggedIn == false && getValue==true) { 
            this.setState({isLoggedIn:true});
        }
        
    }
    
    changeHandleLoading (loading) {
        
    }

     
    render() {
        const loginClass =  this.state.isLoggedIn == true  ? 'theme-red' : 'login-page'; 
        if(this.state.loaderShow){
            return <Loader isloading={true}/>
        }

        if(window.location.pathname=='/' && this.state.isLoggedIn) {
            return <Redirect to='/dashboard'/>
        }

        return (
            <div className={loginClass} > 
                
                {  this.state.isLoggedIn == true && <div> <Header  dashboard={this.props} /> <Menu     dashboard={this.props} /></div> }

            <Switch>
                <Route path="/dashboard" exact={true}  render={ (props) => <Dashboard  {...props} dashboard={this.props} isLoggedIn={this.props.isLoading}   />} />

                <Route path="/schools/:action/:id?/:student_id?" exact={true}  render={ (props) => <Schools {...props}  dashboard={this.props}  />} />
                <Route path="/courses/:action/:id?" exact={true}  render={ (props) => <Courses   {...props} dashboard={this.props} isLoggedIn={this.props.isLoading}  />} />
                <Route path="/subjects/:action/:id?" exact={true}  render={ (props) => <Subjects   {...props} dashboard={this.props} isLoggedIn={this.props.isLoading}  />} />
                
                <Route path="/students/:action/:id?" exact={true}  render={ (props) => <Students   {...props} isLoggedIn={this.props.isLoading} dashboard={this.props}  />} />

                <Route path="/users/:action/:id?" exact={true}  render={ (props) => <Users {...props}  dashboard={this.props}  />} />
                <Route path="/" exact={true}  render={ (props) => <Login  {...props} dashboard={this.props} updateLogin={this.setLogin}     />} />
            </Switch>

              { this.state.isLoading &&  <div className="page-loader-wrapper">
                    <div className="loader">
                        <div className="preloader">
                            <div className="spinner-layer pl-red">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                        <p>Please wait...</p>
                    </div>
                </div>
                }
            </div>

        );
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Layout);
