import {
    ADD_USER,
    USER_PAGE_LOADED,
    DELETE_USER,
    GET_USER,
    UPDATE_USER
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case USER_PAGE_LOADED: 
      console.log(action);
        return {
          ...state,
          loading:false,
          pages: action.pages ? action.pages : 0,
          datas: action.data.items ,
          current_page: action.data.list.current_page,
          total: action.data.list.total,
          last_page: action.data.list.last_page,
        };
      case GET_USER:
      case UPDATE_USER:
      case ADD_USER: 
        return {
          ...state,
          data:action.data,
          action:action.type,
          success:action.success ? true : false,
          dataErrors: action.errors ? action.errors : null
           
        };
        
      case DELETE_USER:
        const itemId = action.itemId
        return {
          ...state,
          datas: state.datas.filter(data => data.id !== itemId)
        };
      default:
        return state;
    }
  };
  