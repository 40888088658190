import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

//const HOST_ROOT = 'http://schoolapi.localhost.com';
//const API_ROOT = 'http://schoolapi.localhost.com/api';

const HOST_ROOT = 'https://centerapi.bsve.org.in';
const API_ROOT = 'https://centerapi.bsve.org.in/api';

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = localStorage.getItem('access_token');
const tokenPlugin = req => {
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
   // req.set('Access-Control-Allow-Origin','*');
    
    req.set('accept','application/json');
   // req.set('Content-Type','application/json');
  }
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

 

const limit = (count, p) => `limit=${count}&page=${p}&offset=${p ? p * count : 0}`;
const omitSlug = school => Object.assign({}, school, { id: undefined })

const Schools = {
  all: (page,query) =>
    requests.get(`/schools?${limit(10, page)}&${query}`), 
  del: id =>
    requests.del(`/schools/${id}`),  
  get: id =>
    requests.get(`/schools/${id}`), 
  update: (id,school) =>
    requests.put(`/schools/${id}`, school),
  create: school =>
    requests.post('/schools', school),
   getSubject: () =>
    requests.get('/schools/get_subject_course',{})
};

const Students = {
  all: (sid,page,query) =>     
    requests.get(`/students/${sid}?${limit(10, page)}&${query}`),
  del: (id) =>
    requests.del(`/students/${id}`),  
  get: (sid,id) =>
    requests.get(`/students/${sid}/${id}`),
  getStudent: (id) =>
    requests.get(`/students/get-student/${id}`), 
  getStudentByRollNumber: (id) =>
    requests.get(`/students/get-student-rollnumber/${id}`),
  updateStudent: (id,data) =>
    requests.put(`/students/update/${id}`, data),
  update: (sid,id,data) =>
    requests.put(`/students/${sid}/${id}`, data),
  admit_card: (student_id) =>
    requests.get(`/students/generate-admin-card/${student_id}`),
  upload: (sid,id,data) =>
    requests.post(`/students/upload/${sid}/${id}`, data),
  updateMarks: (sid,id,data) =>
    requests.post(`/students/marks/${sid}/${id}`, data),
  create: (sid,data) =>
    requests.post(`/students/${sid}`, data),
};


const Courses = {
  all: (page,query) =>
    requests.get(`/courses?${limit(10, page)}&${query}`), 
  del: id =>
    requests.del(`/courses/${id}`),  
  get: id =>
    requests.get(`/courses/${id}`), 
  update: (id,school) =>
    requests.put(`/courses/${id}`, school),
  create: school =>
    requests.post('/courses', school)
};

const Subjects = {
  all: (page,query) =>
    requests.get(`/subjects?${limit(10, page)}&${query}`), 
  del: id =>
    requests.del(`/subjects/${id}`),  
  get: id =>
    requests.get(`/subjects/${id}`), 
  update: (id,school) =>
    requests.put(`/subjects/${id}`, school),
  create: school =>
    requests.post('/subjects', school)
};


const Users = {
  all: (page,query) =>
    requests.get(`/school-user?${limit(10, page)}&${query}`), 
  del: id =>
    requests.del(`/school-user/${id}`),  
  get: id =>
    requests.get(`/school-user/${id}`), 
  changePassword: (id,user) =>
    requests.put(`/user/change-password`, user),
  update: (id,user) =>
    requests.put(`/school-user/${id}`, user),
  create: user =>
    requests.post('/school-user', user)
};


const Auth = {
  current: () =>
    requests.get('/user'),
  login: (email, password) =>
    requests.post('/user/login', { user: { email, password } }),
  register: (username, email, password) =>
    requests.post('/users', { user: { username, email, password } }),
  save: user =>
    requests.put('/user', { user })
};

const Dashboard = {
  current: () =>
    requests.get('/dashboard'), 
};

export default {
  Schools,
  Courses, 
  Subjects,
  Students,
  Users,
  Auth,
  Dashboard,
  HOST_ROOT,
  API_ROOT,
  setToken: _token => { token = _token; }
};
